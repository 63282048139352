import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Classroom } from './pages/Classroom';
import { Error } from './pages/Error';
import { Home } from './pages/Home';
import { Lessons } from './pages/Lessons';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: '/home',
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: '/classroom/:id',
    element: <Classroom />,
    errorElement: <Error />,
  },
  {
    path: '/lessons/:id',
    element: <Lessons />,
    errorElement: <Error />,
  },
]);

export default function Routes() {
  return <RouterProvider router={router} />;
}
