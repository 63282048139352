import {
  Box, Flex, Heading, Text, useBreakpointValue,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderImage } from '~/assets';
import { Card, NavBar } from '~/components';
import { DATA } from '~/data/data';

export default function Home() {
  const navigate = useNavigate();
  const isFullWidth = useBreakpointValue({
    base: true,
    lg: false,
  });

  const all = useMemo(() => DATA, [])
    .map(((item) => (
      <Card
        key={item.id}
        title={item.title}
        subTitle={item.subTitle}
        description={item.description}
        time={item.time}
        date={item.date}
        fullWidth={isFullWidth}
        onClick={() => navigate(`/classroom/${item.id}`, { state: { ...item, ok: '?OK?' } })}
      />
    )));
  return (
    <>
      <NavBar />

      <Flex
        flexDirection="column"
        alignItems="flex-start"
        paddingX={12}
      >
        <>
          <Flex
            w="full"
            h="42.75rem"
            borderRadius="xl"
            pb={20}
            pl={[0, 0, 28, 40]}
            alignItems={["center", "center", "flex-start"]}
            justifyContent="flex-start"
            sx={{
              backgroundImage: `linear-gradient(180deg, rgba(2, 111, 244, 0) 0%, rgba(2, 111, 244, 0.06096) 15.48%, rgba(2, 111, 244, 0.2) 100%), url(${HeaderImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Flex
              width={["full", "95%", "80%", "60%"]}
              height="full"
              flexDirection="column"
              alignItems={["center", "center", "flex-start"]}
              justifyContent="flex-end"
              textAlign="center"
            >
              <Heading
                size="3xl"
                color="whiteAlpha.900"
              >
                Seja Bem-vindo ao CapSchool
              </Heading>
              <Text
                fontSize="2xl"
                color="whiteAlpha.900"
              >
                Aprenda sobre crypto, programação, educação financeira, cultura do Capitual e muito mais!
              </Text>
            </Flex>
          </Flex>
          <Text
            fontSize="2xl"
            color="blackAlpha.900"
            marginTop={14}
            marginBottom={10}
          >
            Mini Cursos
          </Text>

          <Flex
            flexDirection={["column", "column", "row"]}
            flexWrap="wrap"
            justifyContent="center"
            gap={6}
          >
            {all}
          </Flex>

          <Box w="full" h="40px" />
        </>
      </Flex>
    </>
  );
}
