import { useMemo } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';
import { Card, NavBar } from '~/components';
import { Container } from './Lessons.styles';

export default function Lessons() {
  const { state } = useLocation();

  const content = useMemo(() => state?.lessons || [], [state])
    .map(((item: any) => (
      <Card
        key={item.id}
        title={item.title}
        subTitle={item.subTitle}
        description={item.description}
        time={item.time}
        onClick={() => {
          window.location.href = item.link;
        }}
        fullWidth
        isChecked={item.isChecked}
      />
    )));
  // const requisite = useMemo(() => state?.requisite || [], [state]);
  // const time = useMemo(() => state?.time || '', [state]);
  // const modules = useMemo(() => state?.modules || [], [state]);
  console.log(state);
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      width="100%"
      py={0}
      px={12}
      gap={14}
    >
      <NavBar />
      <Container>
        <div className="accordion-contents">
          <Accordion
            defaultIndex={[0, 1]}
            allowMultiple
            minW="full">

            <AccordionItem border="none">
              <AccordionButton>
                <Heading
                  size="2xl"
                  color="blackAlpha.900"
                >
                  {state?.title}
                </Heading>
                <Box as="span" fontSize="4xl" flex="1" textAlign="left">
                </Box>
                <Heading
                  size="2xl"
                  color="blackAlpha.900"
                >
                  Aulas
                </Heading>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Flex flexDirection="column" gap={10} mt={10}>
                  {content}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <Box w="100%" h="40px" />
      </Container>
    </Flex>
  );
}
