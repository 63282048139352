import styled from 'styled-components';
import { GeneralStylesProps } from '~/typings/styles';
// import { colors, fonts } from '~/styles';
// import { GeneralStylesProps } from '~/typings/styles';

export const Container = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 46px;

  header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 576px;
    width: 100%;
    background-color: colors.grayOpacity10;
    border-radius: 12px;

    div{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 100px;
      border: 1px solid colors.blackMain;
    }

    img{
      height: 576px;
      width: 100%;
      border-radius: 12px;
    }
  }
  .accordion-contents{
    width: 100%;
  } */
`;

export const Accordion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0px;
`;

export const AccordionContent = styled.div<GeneralStylesProps>`
  /* display: (p) => (p.show ? 'flex' : 'none');
  flex-direction: column;
  width: 100%;
  padding: 28px 0px;
  gap: 30px 10px;
 
  li{
    font-size: 20px;
    color: colors.grayLight;
    font-family: fonts.regular;
    margin-left: 105px;
    margin-top: 5px;
  } */
`;
