import { Global } from '@emotion/react';

function Fonts() {
  return (
    <Global
      styles={`
      /* latin */
      @font-face {
        font-family: "Helvetica Now Display Bold";
        src: local("HelveticaNowDisplay-Bold"), url('../assets/fonts/HelveticaNowDisplay-Bold.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: "Helvetica Now Display Medium";
        src: local("HelveticaNowDisplay-Medium"), url('../assets/fonts/HelveticaNowDisplay-Medium.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: "Helvetica Now Display Regular";
        src: local("HelveticaNowDisplay-Regular"), url('../assets/fonts/HelveticaNowDisplay-Regular.ttf') format('truetype');
      }
    `}
    />
  );
}

export default Fonts;
