export default function CalendarIcon({ size = 24, color = '#C3C6DB' }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7183 6.5L6.71716 6.50001C5.0603 6.5 3.7177 7.84315 3.71773 9.50001L3.71779 17.4998C3.71775 19.1567 5.05674 20.5 6.71368 20.5H16.7223C18.3792 20.5 19.7178 19.1572 19.7178 17.5003V9.49969C19.7178 7.84284 18.3752 6.5 16.7183 6.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.7178 4.45801V8.45801" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.7179 4.5V8.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.71765 11.458L19.7177 11.458" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
