import { extendTheme } from '@chakra-ui/react';

// example theme
const theme = extendTheme({
  colors: {
    transparent: 'transparent',
    primary: {
      500: '#026FF4',
      200: '#0F90FF',
    },
    success: {
      500: '#00E699',
    },
    warning: {
      500: '#f3bA2f',
    },
    danger: {
      500: '#ff6378',
    },
    white: '#ffffff',
    gray: {
      50: '#F8F9FB',
      100: '#F3F4F9',
      300: '#C3C6DB',
      500: '#9EA1B9',
      700: '#757893',
      900: '#272937',
    },
    black: '#000000',
    grayAlpha: {
      100: 'rgba(195, 198, 219, 0.1)',
    },
    blackAlpha: {
      50: 'rgba(0, 0, 0, 0.05)',
    },
  },
  fonts: {
    helveticaBold: 'Helvetica Now Display Bold',
    helveticaMedium: 'Helvetica Now Display Medium',
    helveticaRegular: 'Helvetica Now Display Regular',
  },
  styles: {
    global: {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      body: {
        bg: 'white',
        color: 'black',
      },
    },
  },
});

export default theme;
