export default function CheckIcon({
  size = 40, color = '#272937', checked = false,
}) {
  console.log('checked', checked);
  return (
    <svg width={size} height={size} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20.5" r="15" stroke={color} strokeWidth="1.5" />

      {checked && (
        <path d="M13.3333 21.3333L17.7778 25.5L26.6666 17.1667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </svg>
  );
}
