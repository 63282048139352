import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem,
  Image,
} from '@chakra-ui/react';
import {
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BookIcon, LockIcon, StreamingIcon, WatchIcon,
} from '~/assets';
import {
  Card,
  NavBar,
  Unless,
  When,
} from '~/components';

export default function Classroom() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const content = useMemo(() => state?.modules || [], [state])
    .map(((item: any) => (
      <Card
        key={item.id}
        title={item.title}
        subTitle={item.subTitle}
        description={item.description}
        time={item.time}
        date={item.date}
        disabled={item.date === 'Em breve!'}
        onClick={() => {
          if (item.date === 'Em breve!') {
            return;
          }
          if (item?.lessons?.length > 0) {
            navigate(`/lessons/${item.id}`, { state: { ...item } });
            return;
          }
          window.location.href = item.link;
        }}
        fullWidth
        isChecked={item.isChecked}
      />
    )));
  const requisite = useMemo(() => state?.requisite || [], [state]);
  const time = useMemo(() => state?.time || '', [state]);
  const modules = useMemo(() => state?.modules || [], [state]);

  return (
    <>
      <NavBar />
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        py={0}
        px={12}
        gap={14}
      >
        <Flex
          w="full"
          h="42.75rem"
          alignItems="center"
          justifyContent="center"
          backgroundColor="grayAlpha.100"
          borderRadius="xl"
        >
          <When condition={!!state.img}>
            <Image
              boxSize="full"
              src={state.img}
              fit='cover'
              borderRadius="xl"
              alt=""
            />
          </When>
          <Unless condition={!!state.img}>
            <StreamingIcon />
          </Unless>
        </Flex>
        <Heading
          size="2xl"
          color="blackAlpha.900"
        >
          {state?.title}
        </Heading>

        <Accordion
          defaultIndex={[0, 1]}
          allowMultiple
          minW="full"
        >
          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box as="span" fontSize="4xl" flex="1" textAlign="left">
                  Ementa
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel marginTop={10} marginBottom={10} pb={4} bg="gray.50" borderRadius="lg">
              <Flex flexDirection="column" gap={5} mt={10}>
                <Flex flexDirection="row" alignItems="center" justifyContent="flex-start" gap={2}>
                  <LockIcon />
                  <Heading as="h4" size="lg">
                    Requisitos:
                  </Heading>
                </Flex>
                <Flex flexDirection="column" gap={2.5}>
                  {requisite.map((req: string) => (
                    <Text fontSize="2xl" key={req} marginLeft={8}>{req}</Text>
                  ))}
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap={5} mt={10}>
                <Flex flexDirection="row" alignItems="center" justifyContent="flex-start" gap={2}>
                  <WatchIcon />
                  <Heading as="h4" size="lg">
                    Carga Hóraria:
                  </Heading>
                </Flex>
                <Text fontSize="2xl" marginLeft={8}>{time}</Text>
              </Flex>

              <Box mt={10}>
                {modules.length > 0 && (
                  <>
                    <Flex flexDirection="row" alignItems="center" justifyContent="flex-start" gap={2}>
                      <BookIcon />
                      <Heading as="h4" size="lg">
                        Módulos:
                      </Heading>
                    </Flex>
                    <Flex flexDirection="column" gap={2.5} marginLeft={8}>
                      {modules.map((mod: any) => (
                        <Box key={mod.id} mt={5}>
                          <Text fontSize="2xl" lineHeight="short">{mod.title}</Text>

                          <UnorderedList mt={3.5} color="gray.700">
                            <ListItem ml={8}>
                              Descrição:  {mod.description}
                            </ListItem>

                            <ListItem mt={3.5} ml={8}>
                              Duração:  {mod.time}
                            </ListItem>

                            <ListItem mt={3.5} ml={8}>
                              Tags:   {mod.tags.join(', ')}
                            </ListItem>
                          </UnorderedList>
                        </Box>
                      ))}
                    </Flex>
                  </>
                )}
              </Box>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem border="none">
            <h2>
              <AccordionButton>
                <Box as="span" fontSize="4xl" flex="1" textAlign="left">
                  Módulos
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Flex flexDirection="column" gap={10} mt={10}>
                {content}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Box w="100%" h="40px" />
      </Flex>
    </>
  );
}
