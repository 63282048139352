import {
  Flex, Heading, Text,
} from '@chakra-ui/react';
import { Unless, When } from 'react-if';
import {
  CalendarIcon, CheckIcon, ProcessingIcon, StreamingIcon,
} from '~/assets';
import { CardProps } from './Card.types';

export default function Card({
  title,
  subTitle,
  description,
  time,
  date,
  isChecked,
  fullWidth,
  disabled,
  onClick,
}: CardProps) {
  return (
      <Flex
        flexDirection="row"
        w={fullWidth ? 'full' : '27rem'}
        minW={fullWidth ? 'full' : '27rem'}
        h={fullWidth ? ['14rem', '14rem', '14rem', '11rem'] : '2xs'}
        justifyContent="flex-start"
        border="1px"
        borderStyle="solid"
        borderColor="gray.300"
        borderRadius="lg"
        cursor="pointer"
        onClick={onClick}
        style={{
          cursor: disabled ? 'not-allowed': 'pointer',
          opacity: disabled ? 0.6:1
        }}
      >
        <Flex
          w={24}
          minW={24}
          h="auto"
          alignItems="center"
          justifyContent="center"
        >
          <When condition={fullWidth}>
            <When condition={isChecked}>
              <CheckIcon checked={isChecked} />
            </When>
            <Unless condition={isChecked}>
              <CheckIcon />
            </Unless>
          </When>
          <Unless condition={fullWidth}>
            <StreamingIcon />
          </Unless>
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          pt={4}
          px={3.5}
          pb={2.5}
          gap="5px"
          position="relative"
        >
          <When condition={fullWidth}>
            <Heading as="h3" size="lg" noOfLines={2}>{title}</Heading>
            <Text fontSize="md" marginTop={1} marginBottom={1}>{subTitle}</Text>
          </When>
          <Unless condition={fullWidth}>
            <Text fontSize="md" lineHeight="short">{subTitle}</Text>
            <Heading as="h3" fontSize="1.5rem" lineHeight="tall" noOfLines={1}>{title}</Heading>
          </Unless>

          <When condition={!!description}>
            <Text fontSize="md" lineHeight="short" noOfLines={fullWidth ? 2 : 4}>
              {description}
            </Text>
          </When>

          <When condition={!!time || !!date}>
            <Flex
              w="full"
              h="3.125rem"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={7}
              position="absolute"
              bottom={2.5}
              left={3.5}
            >
              <When condition={!!time}>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={2}
                >
                  <ProcessingIcon />
                  <Text fontSize="md" lineHeight="short" color="gray.300">{time}</Text>
                </Flex>
              </When>

              <When condition={!!date}>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap={2}
                >
                  <CalendarIcon />
                  <Text fontSize="md" lineHeight="short" color="gray.300">{date}</Text>
                </Flex>
              </When>
            </Flex>
          </When>
        </Flex>
      </Flex>

  );
}
