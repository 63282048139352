import { HamburgerIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Image, Link, Menu, MenuButton, MenuItem, MenuList, Show } from '@chakra-ui/react';
import { LogoImage } from '~/assets';

export default function NavBar() {
  return (
    <Flex
      w="full"
      h={20}
      paddingX={12}
      flexDirection="row"
      alignItems="center"
      justifyContent={['space-between', 'space-between', 'flex-start']}
      gap={24}
    >
      <Image src={LogoImage} alt="Capitual logo" />
      <Show below='md'>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='outline'
          />
          <MenuList>
            <MenuItem >
              Home
            </MenuItem>
            <MenuItem >
              Turmas
            </MenuItem>
            <MenuItem >
              CapSchoolExplica
            </MenuItem>
            <MenuItem >
              Manual do Capitual
            </MenuItem>
          </MenuList>
        </Menu>
      </Show>

      <Show above='md'>
        <Link
          href="/"
          fontSize="md"
          lineHeight="normal"
          textDecoration="none"
          color="blackAlpha.900"
        >
          Home
        </Link>

        <Link
          href="/"
          fontSize="md"
          lineHeight="normal"
          textDecoration="none"
          color="blackAlpha.900"
        >
          Manual do Capitual
        </Link>

        <Link
          href="/"
          fontSize="md"
          lineHeight="normal"
          textDecoration="none"
          color="blackAlpha.900"
        >
          Turmas
        </Link>

        <Link
          href="https://drive.google.com/file/d/1LOKz5oSQ_wH1cXSO7XESLg-YnN-qWgCp/view?usp=share_link"
          fontSize="md"
          lineHeight="normal"
          textDecoration="none"
          color="blackAlpha.900"
        >
          CapSchoolExplica
        </Link>
      </Show>
    </Flex>
  );
}
