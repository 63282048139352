import { Heading, Text } from '@chakra-ui/react';
import { Container } from './Error.styles';
import { ErrorProps } from './Error.types';

export default function Error({ type = 'default' }: ErrorProps) {
  console.log(type);
  return (
    <Container>
      <Heading as="h1" size="xl">
        Ops !!!
      </Heading>
      <Heading as="h3" size="lg">
        Sorry, an unexpected error has occurred.
      </Heading>
      <Text size="md">
        Not Found
      </Text>
    </Container>
  );
}
