import {
  MundoCryptoImage,
  InteligenciaFinanceiraImage,
  ProgramacaoImage,
} from '~/assets';

export const DATA = [ // COURSES
  {
    id: '1',
    title: 'Mundo Crypto',
    subTitle: 'Mini Curso',
    description: 'O que é Blockchain? Por que "cripto" moedas? O que é Bitcoin? O que é um Satoshi? O que são smart contracts? O que é uma DAO? Como tudo isso pode influenciar a minha vida?',
    time: '5hrs',
    date: '21/12/2022',
    isChecked: false,
    img: MundoCryptoImage,
    requisite: ['Nenhum, apenas sede de conhecimento!'],
    modules: [
      {
        id: '1',
        title: 'Mundo Crypto - Bitcoin',
        subTitle: 'Módulo 01',
        description: 'Uma versão de moeda digital puramente peer-to-peer',
        time: '30min',
        date: '21/12/2022',
        isChecked: false,
        tags: ['Bitcoin', 'Transações', 'Timestamp', 'Proof-of-Work', 'Blockchain', 'Privacidade', 'Segurança'],
        link: 'https://drive.google.com/file/d/18vmtw_sm0XHbJYjN7Hwp-r9cXcLHfVoB/view?usp=share_link',
      },
      {
        id: '2',
        title: 'Mundo Crypto - CBDC',
        subTitle: 'Módulo 02',
        description: 'Central Bank Digital Currency',
        time: '30min',
        date: '15/12/2022',
        isChecked: false,
        tags: ['CBDC', 'Real Digital', 'LIFT Challenge', 'Projeto Jasper', 'Projeto Hamilton', 'Regulação'],
        link: 'https://drive.google.com/file/d/1s5oMeh22GMpdurkA2FRtra5eltWytSXc/view?usp=share_link',
      },
      {
        id: '3',
        title: 'Mundo Crypto - Resolução de Conflitos Blockchain',
        subTitle: 'Módulo 03',
        description: 'Resolução de disputas para transações em redes permissionadas',
        time: '1hr',
        date: '26/12/2022',
        isChecked: false,
        tags: ['Conflitos', 'Disputas', 'Governança', 'On-chain', 'Off-chain', 'Blockchain'],
        link: 'https://drive.google.com/file/d/1Wo2ptv50coLhb0Bnzxfhq7Bdk-7_EaCG/view?usp=share_link',
      },
      {
        id: '4',
        title: 'Mundo Crypto - Compliance Legal e Regulatório',
        subTitle: 'Módulo 04',
        description: 'Problemas legais e regulatórios comuns com o uso de blockchain',
        time: '1hr',
        date: '04/01/2023',
        isChecked: false,
        tags: ['Compliance', 'Conformidade', 'Regulatório', 'Legal', 'Blockchain', 'Jurídico', 'Jurisidção'],
        link: 'https://drive.google.com/file/d/1R_OgcgryI6TYVjGVMaMm5_RzyarRYHqc/view?usp=share_link',
      },
      {
        id: '5',
        title: 'Mundo Crypto - Contratos Inteligentes',
        subTitle: 'Módulo 05',
        description: 'Contratos inteligentes auto-executáveis usando blockchain',
        time: '30min',
        date: '10/01/2023',
        isChecked: false,
        tags: ['Contrato Inteligente', 'Smart Contract', 'Escrow'],
        link: 'https://drive.google.com/file/d/1Za8wOcruIEBmub8_EgFL4bM7Qdo-TfzA/view?usp=share_link',
      },
    ],
  },
  {
    id: '2',
    title: 'Inteligência Financeira',
    subTitle: 'Mini Curso',
    description: 'Aprenda a administar melhor seu dinheiro. Entenda como se organizar e gerir suas finanças de uma maneira simples e prática',
    time: '1hr',
    date: '12/01/2023',
    isChecked: false,
    img: InteligenciaFinanceiraImage,
    requisite: ['Nenhum, apenas sede de conhecimento!'],
    modules: [
      {
        id: '1',
        title: 'Inteligência Financeira',
        subTitle: 'Módulo 01',
        description: 'Aprenda sobre planejamento, gastos e quite sua dívidas',
        time: '1hr',
        date: '12/01/2023',
        isChecked: false,
        tags: ['Finanças', 'Planejamento', 'Dívidas', 'Dinheiro', 'Gastos'],
        link: 'https://drive.google.com/file/d/1xWXbvdeAQoFMopIFRuBb7Cx3dEZKB8oA/view?usp=share_link',
      },
    ],
  },
  {
    id: '3',
    title: 'Programação - Iniciante',
    subTitle: 'Mini Curso',
    description: 'O que é programação? O que é FrontEnd e BackEnd? Quais são as profissões de programadores? Aprenda sobre teoria e também inicia a prática com HTML e CSS!',
    time: '8hrs 30min',
    date: '01/11/2022',
    isChecked: false,
    img: ProgramacaoImage,
    requisite: ['Nenhum, apenas sede de conhecimento!'],
    modules: [
      {
        id: '1',
        title: 'Introdução a Programação',
        subTitle: 'Módulo 01',
        description: 'Introdução ao universo desafiador da programação',
        time: '30min',
        date: '11/01/2023',
        isChecked: false,
        tags: ['Programação', 'Linguagens', 'Front End', 'Back End', 'Profissão'],
        link: 'https://drive.google.com/file/d/1_afY_-7o0U-jyH6i0gxAufr0aqbeuDab/view?usp=share_link',
        lessons: [],
      },
      {
        id: '2',
        title: 'HTML e CSS Básico',
        subTitle: 'Módulo 02',
        description: 'Estrutura HTML, ID, Class, Tags, Seletores e muito mais!',
        time: '8hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['HTML', 'CSS'],
        link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24622320#overview',
        lessons: [
          {
            id: '1',
            title: 'Estrutura HTML',
            subTitle: 'Aula 01 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Estrutura'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24622320#overview',
          },
          {
            id: '2',
            title: 'Estrutura HTML e Meta ViewPort',
            subTitle: 'Aula 02 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Estrutura'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24622476#overview',
          },
          {
            id: '3',
            title: 'Atributos ID e Class',
            subTitle: 'Aula 03 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Id', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24622488#overview',
          },
          {
            id: '4',
            title: 'Headings H1 ao H6',
            subTitle: 'Aula 04 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Heading'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24623094#overview',
          },
          {
            id: '5',
            title: 'Tags HTML para Textos',
            subTitle: 'Aula 05 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '35min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Tags'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24624900#overview',
          },
          {
            id: '6',
            title: 'Todas as Tags HTML',
            subTitle: 'Aula 06 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '5min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Tags'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24636770#overview',
          },
          {
            id: '7',
            title: 'Pensando em HTML e CSS',
            subTitle: 'Aula 07 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '35min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'CSS'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24629804#overview',
          },
          {
            id: '8',
            title: 'CSS Box-Model',
            subTitle: 'Aula 08 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '35min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['CSS', 'Box-Model'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24634988#overview',
          },
          {
            id: '9',
            title: 'Listas e Menus com HTML e CSS',
            subTitle: 'Aula 09 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'CSS', 'Listas', 'Menus'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24635588#overview ',
          },
          {
            id: '10',
            title: 'Tabelas HTML e CSS',
            subTitle: 'Aula 10 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'CSS', 'Tabelas'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24662292#overview ',
          },
          {
            id: '11',
            title: 'Seletores Básicos CSS: Parte 1',
            subTitle: 'Aula 11 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['CSS', 'Seletores'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24673412#overview ',
          },
          {
            id: '12',
            title: 'Seletores Básicos CSS: Parte 2',
            subTitle: 'Aula 12 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['CSS', 'Seletores'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24673664#overview ',
          },
          {
            id: '13',
            title: 'Formulários e Inputs HTML: Parte 1',
            subTitle: 'Aula 13 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Form', 'Formulário'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24665832#overview ',
          },
          {
            id: '14',
            title: 'Formulários e Inputs HTML: Parte 2',
            subTitle: 'Aula 14 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Form', 'Formulário'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24665856#overview ',
          },
          {
            id: '15',
            title: 'Formulários e Inputs HTML: Parte 3',
            subTitle: 'Aula 15 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Form', 'Formulário'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24665868#overview',
          },
          {
            id: '16',
            title: 'Formulários e Inputs HTML: Parte 4',
            subTitle: 'Aula 16 - Módulo 02 - Programação Iniciante',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['HTML', 'Form', 'Formulário'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/24665870#overview',
          },
        ],
      },
      {
        id: '3',
        title: 'Projeto de Conclusão',
        subTitle: 'Módulo 03',
        description: 'Construa uma landing page',
        time: '3hrs',
        date: 'Em breve!',
        isChecked: false,
        tags: ['HTML', 'CSS'],
        link: '',
        lessons: [],
      },
    ],
  },
  {
    id: '4',
    title: 'Programação - Intermediário',
    subTitle: 'Mini Curso',
    description: 'Java quem? Prepare-se para aprender sobre a linguagem de programação JavaScript com conceitos básicos e lógica de programação, finalizando com um projeto de conclusão para testar sua criatividade.',
    time: '16hrs 30min',
    date: '01/11/2022',
    isChecked: false,
    img: ProgramacaoImage,
    requisite: ['Ter concluído Programação - Iniciante'],
    modules: [
      {
        id: '1',
        title: 'JavaScript Básico',
        subTitle: 'Módulo 01',
        description: 'Variáveis, Constantes, Tipo de dados, Operadores, e muito mais!',
        time: '7hrs 30min',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Variáveis',
            subTitle: 'Aula 01 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Variáveis', 'Var', 'Let'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16348666#overview',
          },
          {
            id: '2',
            title: 'Constantes',
            subTitle: 'Aula 02 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Constantes', 'Const'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16351090#overview',
          },
          {
            id: '3',
            title: 'Tipos de Dados',
            subTitle: 'Aula 03 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'String', 'Number', 'Boolean', 'Undefined', 'Null', 'Dados'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16358638#overview',
          },
          {
            id: '4',
            title: 'Operadores Aritméticos',
            subTitle: 'Aula 04 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Adicionar', 'Subtrair', 'Multiplicar', 'Dividir', 'Math', 'Operações', 'Aritmética'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16366452#overview',
          },
          {
            id: '5',
            title: 'Strings',
            subTitle: 'Aula 05 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Strings'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16372722#overview',
          },
          {
            id: '6',
            title: 'Numbers',
            subTitle: 'Aula 06 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Números', 'Numbers'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16386478#overview',
          },
          {
            id: '7',
            title: 'Arrays',
            subTitle: 'Aula 07 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Vetores'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16397746#overview',
          },
          {
            id: '8',
            title: 'Funções',
            subTitle: 'Aula 08 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Functions'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16399650#overview',
          },
          {
            id: '9',
            title: 'Objetos',
            subTitle: 'Aula 09 - Módulo 01 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16400888#overview',
          },
        ],
      },
      {
        id: '2',
        title: 'Lógica de Programação',
        subTitle: 'Módulo 02',
        description: 'Operadores de comparação, If, Else, Estruturas de repetição, e muitos mais!',
        time: '9hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Operadores de Comparação',
            subTitle: 'Aula 01 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Operadores comparação'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16420948#overview',
          },
          {
            id: '2',
            title: 'Operadores Lógicos',
            subTitle: 'Aula 02 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Operadores lógicos'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16422148#overview',
          },
          {
            id: '3',
            title: 'If, Else, If Else: Parte 1',
            subTitle: 'Aula 03 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'If', 'Else', 'If Else'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16423482#overview',
          },
          {
            id: '4',
            title: 'If, Else, If Else: Parte 2',
            subTitle: 'Aula 04 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'If', 'Else', 'If Else'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16433744#overview',
          },
          {
            id: '5',
            title: 'Operação Ternária',
            subTitle: 'Aula 05 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'If Ternário'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16475382#overview',
          },
          {
            id: '6',
            title: 'Objeto Date',
            subTitle: 'Aula 06 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Object', 'Date'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16480164#overview',
          },
          {
            id: '7',
            title: 'Switch/Case',
            subTitle: 'Aula 07 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Switch', 'Case'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16482276#overview',
          },
          {
            id: '8',
            title: 'Desestruturação de Arrays',
            subTitle: 'Aula 08 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Desestruturação'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16491630#overview',
          },
          {
            id: '9',
            title: 'Desestruturação de Objetos',
            subTitle: 'Aula 09 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Desestruturação'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16491644#overview',
          },
          {
            id: '10',
            title: 'Estrutura de Repetição For',
            subTitle: 'Aula 10 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'For', 'Repetição'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16499428#overview',
          },
          {
            id: '11',
            title: 'Estrutura de Repetição For in',
            subTitle: 'Aula 11 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'For in', 'Repetição'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16504506#overview',
          },
          {
            id: '12',
            title: 'Estrutura de Repetição For of',
            subTitle: 'Aula 12 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'For of', 'Repetição'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16506660#overview',
          },
          {
            id: '13',
            title: 'While e Do While',
            subTitle: 'Aula 13 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Do', 'While', 'Repetição'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16508498#overview',
          },
          {
            id: '14',
            title: 'Break e Continue',
            subTitle: 'Aula 14 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Break', 'Continue'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16512994#overview',
          },
          {
            id: '15',
            title: 'Try/Catch/Throw',
            subTitle: 'Aula 15 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Try', 'Catch', 'Throw'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16515560#overview',
          },
          {
            id: '16',
            title: 'Try/Catch/Finally',
            subTitle: 'Aula 16 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Try', 'Catch', 'Finally'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16518522#overview',
          },
          {
            id: '17',
            title: 'setInterval e setTimeout',
            subTitle: 'Aula 17 - Módulo 02 - Programação Intermediário',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'setInterval', 'setTimeout'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16519864#overview',
          },
        ],
      },
      {
        id: '3',
        title: 'Projeto de Conclusão',
        subTitle: 'Módulo 03',
        description: 'Projeto de conclusão em breve!',
        time: '3hrs',
        date: 'Em breve!',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [],
      },
    ],
  },
  {
    id: '5',
    title: 'Programação - Avançado',
    subTitle: 'Mini Curso',
    description: 'Domine funções, array e objetos. Se você chegou aqui, parabéns você manja de JavaScript. Consegue ir para o último nível da jornada?',
    time: '12hrs',
    date: '01/11/2022',
    isChecked: false,
    img: ProgramacaoImage,
    requisite: ['Ter concluído Programação - Iniciante', 'Ter concluído Programação - Intermediário'],
    modules: [
      {
        id: '1',
        title: 'JavaScript Funções',
        subTitle: 'Módulo 01',
        description: 'Declaração, Parâmetros, Retorno, Escopo, e muito mais!',
        time: '3hrs 30min',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Declaração',
            subTitle: 'Aula 01 - Módulo 01 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Declaração'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16531086#overview',
          },
          {
            id: '2',
            title: 'Parâmetros',
            subTitle: 'Aula 02 - Módulo 01 - Programação Avançado',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Parâmetros'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16531358#overview',
          },
          {
            id: '3',
            title: 'Retorno',
            subTitle: 'Aula 03 - Módulo 01 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Retorno'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16531364#overview',
          },
          {
            id: '4',
            title: 'Escopo Léxico',
            subTitle: 'Aula 04 - Módulo 01 - Programação Avançado',
            description: '',
            time: '5min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Escopo'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16537348#overview',
          },
          {
            id: '5',
            title: 'Closures',
            subTitle: 'Aula 05 - Módulo 01 - Programação Avançado',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Closures'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16537350#overview',
          },
          {
            id: '6',
            title: 'Funções de Callback',
            subTitle: 'Aula 06 - Módulo 01 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Callback'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16538348#overview',
          },
          {
            id: '7',
            title: 'Funções Imediatas (IIFE)',
            subTitle: 'Aula 07 - Módulo 01 - Programação Avançado',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Imediatas'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16538634#overview',
          },
          {
            id: '8',
            title: 'Funções Fábrica (Factory Functions)',
            subTitle: 'Aula 08 - Módulo 01 - Programação Avançado',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Factory'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16539816#overview',
          },
          {
            id: '9',
            title: 'Praticando Factory Functions',
            subTitle: 'Aula 09 - Módulo 01 - Programação Avançado',
            description: '',
            time: '40min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Factory'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16546410#overview',
          },
          {
            id: '10',
            title: 'Funções Construtoras (Constructor Functions)',
            subTitle: 'Aula 10 - Módulo 01 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Construtoras'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16546830#overview',
          },
          {
            id: '11',
            title: 'Calculadora com Constructor Functions',
            subTitle: 'Aula 11 - Módulo 01 - Programação Avançado',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Construtoras'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16551190#overview',
          },
          {
            id: '12',
            title: 'Funções Recursivas',
            subTitle: 'Aula 12 - Módulo 01 - Programação Avançado',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Recursivas'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16556976#overview',
          },
          {
            id: '13',
            title: 'Funções Geradoras',
            subTitle: 'Aula 13 - Módulo 01 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Geradoras'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16556982#overview',
          },
        ],
      },
      {
        id: '2',
        title: 'JavaScript Arrays',
        subTitle: 'Módulo 02',
        description: 'Splice, filter, map, reduce, e muito mais!',
        time: '2hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Revisão de Arrays',
            subTitle: 'Aula 01 - Módulo 02 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16558614#overview',
          },
          {
            id: '2',
            title: 'Método Splice',
            subTitle: 'Aula 02 - Módulo 02 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Splice'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16561540#overview',
          },
          {
            id: '3',
            title: 'Conectando Arrays',
            subTitle: 'Aula 03 - Módulo 02 - Programação Avançado',
            description: '',
            time: '5min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Concatenar'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16562462#overview',
          },
          {
            id: '4',
            title: 'Filter - Filtrando o Array',
            subTitle: 'Aula 04 - Módulo 02 - Programação Avançado',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Filtro'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16562964#overview',
          },
          {
            id: '5',
            title: 'Map - Mapeando o Array',
            subTitle: 'Aula 05 - Módulo 02 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Mapear'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16562966#overview',
          },
          {
            id: '6',
            title: 'Reduce - Reduzindo o Array',
            subTitle: 'Aula 06 - Módulo 02 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Reduce'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16562968#overview',
          },
          {
            id: '7',
            title: 'Filter + Map + Reduce',
            subTitle: 'Aula 07 - Módulo 02 - Programação Avançado',
            description: '',
            time: '5min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'Filter', 'Map', 'Reduce'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16565014#overview',
          },
          {
            id: '8',
            title: 'forEach',
            subTitle: 'Aula 08 - Módulo 02 - Programação Avançado',
            description: '',
            time: '5min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Arrays', 'forEach'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16565016#overview',
          },
        ],
      },
      {
        id: '3',
        title: 'JavaScript Objects e Prototypes',
        subTitle: 'Módulo 03',
        description: 'Conheça detalhes importantes sobre objetos e prototypes!',
        time: '4hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Revisando Objetos',
            subTitle: 'Aula 01 - Módulo 03 - Programação Avançado',
            description: '',
            time: '35min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16573260#overview',
          },
          {
            id: '2',
            title: 'Object.defineProperty() e Object.defineProperties()',
            subTitle: 'Aula 02 - Módulo 03 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16574288#overview',
          },
          {
            id: '3',
            title: 'Getters e Setters',
            subTitle: 'Aula 03 - Módulo 03 - Programação Avançado',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16574844#overview',
          },
          {
            id: '4',
            title: 'Métodos Úteis para Objetos',
            subTitle: 'Aula 04 - Módulo 03 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects', 'Métodos'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16576312#overview',
          },
          {
            id: '5',
            title: 'Prototypes',
            subTitle: 'Aula 05 - Módulo 03 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Prototypes'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16579858#overview',
          },
          {
            id: '6',
            title: 'Manipulando Prototypes',
            subTitle: 'Aula 06 - Módulo 03 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Prototypes'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16582788#overview',
          },
          {
            id: '7',
            title: 'Herança',
            subTitle: 'Aula 07 - Módulo 03 - Programação Avançado',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Herança'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16586022#overview',
          },
          {
            id: '8',
            title: 'Exercício: Validando um CPF',
            subTitle: 'Aula 08 - Módulo 03 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16586700#overview',
          },
          {
            id: '9',
            title: 'Polimorfismo',
            subTitle: 'Aula 09 - Módulo 03 - Programação Avançado',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16591298#overview',
          },
          {
            id: '10',
            title: 'Factory Functions + Prototypes',
            subTitle: 'Aula 10 - Módulo 03 - Programação Avançado',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Funções', 'Functions', 'Factory', 'Prototypes'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16591606#overview',
          },
          {
            id: '11',
            title: 'Objeto Map()',
            subTitle: 'Aula 11 - Módulo 03 - Programação Avançado',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Objetos', 'Objects', 'Map'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/23073684#overview',
          },
        ],
      },
      {
        id: '4',
        title: 'Projeto de Conclusão',
        subTitle: 'Módulo 04',
        description: 'Projeto de conclusão em breve!',
        time: '3hrs',
        date: 'Em breve!',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [],
      },
    ],
  },
  {
    id: '6',
    title: 'Programação - Especialista',
    subTitle: 'Mini Curso',
    description: 'Aprenda sobre Classes e JavaScript Assíncrono. Entre no mundo do NodeJS, testando suas habilidades com ferramentas poderosas: Npm, Express, Prisma e MongoDB.',
    time: '4hrs',
    date: '01/11/2022',
    isChecked: false,
    img: ProgramacaoImage,
    requisite: ['Ter concluído Programação - Iniciante', 'Ter concluído Programação - Intermediário', 'Ter concluído Programação - Avançado'],
    modules: [
      {
        id: '1',
        title: 'JavaScript Classes - POO',
        subTitle: 'Módulo 01',
        description: 'Crie classes, trabalhe com herança, como innstaciar uma classe e muito mais!',
        time: '2hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Criando Classes',
            subTitle: 'Aula 01 - Módulo 01 - Programação Especialista',
            description: '',
            time: '10min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16591762#overview',
          },
          {
            id: '2',
            title: 'Getters e Setters',
            subTitle: 'Aula 02 - Módulo 01 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16593436#overview',
          },
          {
            id: '3',
            title: 'Herança com Classes',
            subTitle: 'Aula 03 - Módulo 01 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16593740#overview',
          },
          {
            id: '4',
            title: 'Métodos de Instância e Estáticos',
            subTitle: 'Aula 04 - Módulo 01 - Programação Especialista',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16598336#overview',
          },
          {
            id: '5',
            title: 'Usando Classes (Validação de CPF)',
            subTitle: 'Aula 01 - Módulo 01 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16599580#overview',
          },
          {
            id: '6',
            title: 'Usando Classes (Validação um Formulário)',
            subTitle: 'Aula 01 - Módulo 01 - Programação Especialista',
            description: '',
            time: '40min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Class'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16603076#overview',
          },
        ],
      },
      {
        id: '2',
        title: 'JavaScript Assíncrono',
        subTitle: 'Módulo 02',
        description: 'Promises, Async e Await, Fetch e muito mais!',
        time: '2hrs',
        date: '01/11/2022',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [
          {
            id: '1',
            title: 'Promises',
            subTitle: 'Aula 01 - Módulo 02 - Programação Especialista',
            description: '',
            time: '25min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16603710#overview',
          },
          {
            id: '2',
            title: 'Métodos Úteis para Promises',
            subTitle: 'Aula 02 - Módulo 02 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16610226#overview',
          },
          {
            id: '3',
            title: 'Async/Await',
            subTitle: 'Aula 03 - Módulo 02 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16613768#overview',
          },
          {
            id: '4',
            title: 'XMLHttpRequest (GET) + Promises',
            subTitle: 'Aula 04 - Módulo 02 - Programação Especialista',
            description: '',
            time: '30min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16615868#overview',
          },
          {
            id: '5',
            title: 'Fetch API (GET)',
            subTitle: 'Aula 05 - Módulo 02 - Programação Especialista',
            description: '',
            time: '15min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16618314#overview',
          },
          {
            id: '6',
            title: 'Fetch API e Axios (JSON)',
            subTitle: 'Aula 06 - Módulo 02 - Programação Especialista',
            description: '',
            time: '20min',
            date: '01/11/2022',
            isChecked: false,
            tags: ['JavaScript', 'Assíncrono', 'Async', 'Promises'],
            link: 'https://www.udemy.com/course/curso-de-javascript-moderno-do-basico-ao-avancado/learn/lecture/16618342#overview',
          },
        ],
      },
      {
        id: '3',
        title: 'NodeJS - Npm, Express, Prisma e MongoDB',
        subTitle: 'Módulo 03',
        description: 'Aprenda a o que é e como usar as tecnologias Npm, Express, Prisma e MongoDB',
        time: '6hrs',
        date: 'Em breve!',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [],
      },
      {
        id: '4',
        title: 'Projeto de Conclusão',
        subTitle: 'Módulo 04',
        description: 'Ptojeto de conclusão em breve!',
        time: '3hrs',
        date: 'Em breve!',
        isChecked: false,
        tags: ['JavaScript'],
        link: '',
        lessons: [],
      },
    ],
  },
];
